export const 
referenceObject = {
  ACCOUNTS: 'ACCOUNTS',
    USERS: 'USERS',
    COM_LOOKUP_GROUPS: 'COM_LOOKUP_GROUPS',
    COM_MODULES: 'COM_MODULES',
    COM_PRIVILEGES: 'COM_PRIVILEGES',
    COM_ROLES: 'COM_ROLES',
    COM_ROLE_PRIVILEGES: 'COM_ROLE_PRIVILEGES',
    COM_STATES: 'COM_STATES',
    ACCOUNT_MODULE_MAPPINGS: 'ACCOUNT_MODULE_MAPPINGS',
    AWS_ACCOUNT_MAPPINGS : 'AWS_ACCOUNT_MAPPINGS',
    ACTIVITIES : 'ACTIVITIES',
    FILES: 'FILES',
    COM_LOOKUPS: 'COM_LOOKUPS',
    LOGIN_HOURS: 'LOGIN_HOURS',
    USER_LOGINS: 'USER_LOGINS',
    BLACKLIST_TOKENS: 'BLACKLIST_TOKENS',
    WEBAUTHN_CREDENTIALS: 'WEBAUTHN_CREDENTIALS',
    EMPLOYEES: 'EMPLOYEES',
    USER_ROLE_MAPPINGS: 'USER_ROLE_MAPPINGS',
    CHAPTERS: 'CHAPTERS',
    CHAPTER_SUBSCRIPTIONS: 'CHAPTER_SUBSCRIPTIONS',
    ZONE : 'ZONE',
    MEMBERSHIP_TYPE: 'MEMBERSHIP_TYPE',
    SUBSCRIPTION_PLANS : 'SUBSCRIPTION_PLANS',
    SUBSCRIPTION_PLAN_PRICE_DETAILS : 'SUBSCRIPTION_PLAN_PRICE_DETAILS',
    TAX_GROUPS : 'TAX_GROUPS',
    FINANCIAL_YEARS : 'FINANCIAL_YEARS',
    LEADS : 'LEADS',
    MEMBERS : 'MEMBERS',
    MEMBER_BUSINESS_MAPPINGS : 'MEMBER_BUSINESS_MAPPINGS',
    MEMBER_BUSINESS_CATEGORY_MAPPINGS : 'MEMBER_BUSINESS_CATEGORY_MAPPINGS',
    MEMBER_BUSINESS_TURNOVERS : 'MEMBER_BUSINESS_TURNOVERS',
    MEMBER_SUBSCRIPTIONS : 'MEMBER_SUBSCRIPTIONS',
    OTP : 'OTP',
    SMS_TEMPLATES : 'SMS_TEMPLATES',
    SPEAKERS : 'SPEAKERS',
    SPEAKER_BUSINESS_MAPPINGS : 'SPEAKER_BUSINESS_MAPPINGS',
    SPEAKER_BUSINESS_CATEGORY_MAPPINGS : 'SPEAKER_BUSINESS_CATEGORY_MAPPINGS',
    SPEAKER_BUSINESS_TURNOVERS : 'SPEAKER_BUSINESS_TURNOVERS',
    THRESHOLDS : 'THRESHOLDS',
    TEAMS : 'TEAMS',
    CUBELAB: 'CUBELABS'

};