// Angular
import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import { excelColumns } from '../models/excelColumns';
import * as fs from 'file-saver';
import moment from 'moment';

@Injectable()
export class ExportService {
    constructor() {}

    exportToCsv(filename: string, rows: any[]) {
        if (!rows || !rows.length) {
          return;
        }
        const separator = ',';
        const keys = Object.keys(rows[0]);
        const csvContent = keys.join(separator) + '\n' +
        rows.map(row => {
            return keys.map(k => {
                let cell = row[k] === null || row[k] === undefined ? '' : row[k];
                cell = cell instanceof Date
                ? cell.toLocaleString()
                : cell.toString().replace(/"/g, '""');
                if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`;
                }
                return cell;
            }).join(separator);
        }).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        return;
    }

    exportCSVFromTable(tableId : string, fileName: string) {
        let element : any = document.getElementById(tableId); 
	    var csvString = '';
        for(var i=0; i < element.rows.length; i++) {
            var rowData = element.rows[i].cells;
            for(var j=0; j<rowData.length;j++){
                csvString = csvString + rowData[j].innerHTML.replace(/<[^>]*>/g, "") + ",";
            }
            csvString = csvString.substring(0, csvString.length - 1);
            csvString = csvString + "\n";
        }
        csvString = csvString.substring(0, csvString.length - 1);
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvString);
        hiddenElement.target = '_blank';
        hiddenElement.download = fileName;
        hiddenElement.click();
        hiddenElement.remove();
  	}

    /**
     * 
     * Refer Links : https://www.npmjs.com/package/exceljs
     * Refer Links : https://www.tektutorialshub.com/angular/how-to-export-to-excel-in-angular/
     * @param workSheetName 
     * @param columns 
     * @param data 
     * @param fileName 
     */
    exportExcel(workSheetName: string = 'Data', columns: excelColumns[], data: any[], fileName: string) {
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet(workSheetName);
        worksheet.columns = columns;
        worksheet.insertRow(1, columns.map(data => {return data['title'];}));
        let rows = [];
        data.forEach(element => {
            let row = [];
            columns.forEach(column => {
                row.push(element[column['field']]);
            });
            rows.push(row);
        });
        worksheet.addRows(rows,"n");
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fileName = fileName + '-' + moment().format('YYYY-MM-DD HH:mm:ss') + '.xlsx';
            fs.saveAs(blob, fileName);
        });
    }

    createOuterBorder(worksheet, start = {row: 1, col: 1}, end = {row: 1, col: 1}, borderWidth = 'medium') {

        const borderStyle = {
            style: borderWidth
        };
        for (let i = start.row; i <= end.row; i++) {
            const leftBorderCell = worksheet.getCell(i, start.col);
            const rightBorderCell = worksheet.getCell(i, end.col);
            leftBorderCell.border = {
                ...leftBorderCell.border,
                left: borderStyle
            };
            rightBorderCell.border = {
                ...rightBorderCell.border,
                right: borderStyle
            };
        }
    
        for (let i = start.col; i <= end.col; i++) {
            const topBorderCell = worksheet.getCell(start.row, i);
            const bottomBorderCell = worksheet.getCell(end.row, i);
            topBorderCell.border = {
                ...topBorderCell.border,
                top: borderStyle
            };
            bottomBorderCell.border = {
                ...bottomBorderCell.border,
                bottom: borderStyle
            };
        }
    };

    numToAlpha(num) {
        let letters = ''
        while (num >= 0) {
            letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
            num = Math.floor(num / 26) - 1
        }
        return letters
    }

    exportExcelWithWorkSheet(workbook, worksheet, columns: excelColumns[], data: any[], fileName: string, totalRowNumber: number = null, enableOuterBorder: boolean = false, header: string = null, extraHeader = 0, customHeader = false, fileNameWithTimeStamp = true) {
        let companyName = JSON.parse(localStorage.getItem('company_name'));
        worksheet.columns = columns.map(data => {return {
            header: data.title, 
            key: data.field,
            width: data.width,
            style: { font: { name: 'Calibri', size: '8' } } 
        };});
        let startCell = this.numToAlpha(0) + 1;
        let endCell = this.numToAlpha(columns.length - 1) + 1;
        worksheet.getCell('A1').value = companyName;
        worksheet.getCell('A1').alignment = {
            vertical : "middle", horizontal : "center"
        };
        worksheet.mergeCells(startCell, endCell);
        if (header) {
            let startCell = this.numToAlpha(0) + 2;
            let endCell = this.numToAlpha(columns.length - 1) + 2;
            worksheet.getCell('A2').value = header;
            worksheet.getCell('A2').alignment = {
                vertical : "middle", horizontal : "center"
            };
            worksheet.mergeCells(startCell, endCell);
            if (!customHeader) {
                worksheet.getRow(3).values = columns.map(d => {return d.title;});
            }
        } else if (!customHeader) {
            worksheet.getRow(2).values = columns.map(d => {return d.title;});
        }
        let rows = [];
        data.forEach(element => {
            let row = [];
            columns.forEach(column => {
                row.push(element[column['field']]);
            });
            rows.push(row);
        });
        worksheet.addRows(rows);
        if (totalRowNumber) {
            totalRowNumber = header ? (data.length + 3) : (data.length + 2);
            totalRowNumber = Number(totalRowNumber) + Number(extraHeader);
            worksheet.getRow(totalRowNumber).font = {
                bold: true,
                size: 8
            };
        }
        if (enableOuterBorder) {
            this.createOuterBorder(worksheet, { row: 1, col: 1 }, { row: data.length + 1, col: columns.length });
        }
        worksheet.getRow(1).font = { underline: 'single', bold: true, size: 12, name: 'Calibri' };
        if (header) {
            worksheet.getRow(2).font = { underline: 'single', bold: true, size: 10, name: 'Calibri' };
            worksheet.getRow(3).font = { underline: 'single', bold: true, size: 8, name: 'Calibri' };
        } else {
            worksheet.getRow(2).font = { underline: 'single', bold: true, size: 8, name: 'Calibri' };
        }
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fileName = fileNameWithTimeStamp ? (fileName + '-' + moment().format('YYYY-MM-DD HH:mm:ss') + '.xlsx') : fileName;
            fs.saveAs(blob, fileName);
        });
    }

    getExcelWorkSheet(workSheetName: string = 'Data') {
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet(workSheetName);
        return {workbook: workbook, worksheet: worksheet};
    }

}
