import { Injectable, EventEmitter } from '@angular/core';
import S3 from 'aws-sdk/clients/s3';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { LocalStorageService } from '../../services/local-storage.service';

@Injectable({
  providedIn : "root"
})
export class UploadFileService {
  BusyModule = new EventEmitter<any>();
  FOLDER = '';
  constructor(
    private toastrService: ToastrService, 
    private authService: ApiService,
    private storage: LocalStorageService
  ) { }
 
  /**
   * 
   * @param file
   * @param basePath 
   * @param attachmentType 
   * @param referenceId 
   * @param referenceObjectId 
   * @param referenceSubId 
   * @param isShowMessage 
   * @param message 
   * @param cb 
   */
  uploadfile(file, basePath, attachmentType : string, referenceId : number, referenceObject : string, referenceSubId : number, isShowMessage : boolean, message: string, cb) {
    this.BusyModule.emit({
      busy : true
    });
    const bucket      = new S3(
      {
        accessKeyId: environment.AWS_ACCESSKEY,
        secretAccessKey: environment.AWS_SECRETKEY,
        region: environment.AWS_S3_DMS_REGION
      }
    );
    const fileName   = new Date().getTime() + '-' + (file.name.replace(/[^a-zA-Z0-9.]/g, ''));
    const pathOfFile = this.FOLDER + basePath + '/' + fileName;
    const params = {
      Bucket: environment.AWS_S3_DMS_BUCKET,
      Key: pathOfFile,
      Body: file,
      ACL: 'private',
      ContentDisposition:'inline',
      ContentType:file.type
    };
    bucket.upload(params,  (err, data) => {
      if (err) {
        this.BusyModule.emit({
          busy : false
        });
        this.toastrService.error('Something went wrong', 'Error!');
        return false;
      } 
      const getObjParams = {
        Bucket: environment.AWS_S3_DMS_BUCKET,
        Key: data.Key,
        Expires: 1800
      }; 
      let uploadObj = {};
      uploadObj['file_name']            = fileName;
      uploadObj['original_file_name']   = file.name;
      uploadObj['file_size']            = file.size;
      uploadObj['file_extension']       = 'jpg';
      uploadObj['aws_doc_key']          = data.Key;
      uploadObj['attachment_type']      = attachmentType;
      uploadObj['reference_id']         = referenceId;  
      uploadObj['reference_object']     = referenceObject;
      uploadObj['reference_sub_id']     = referenceSubId;  
      uploadObj['account_id']           = localStorage.account_id;
      this.authService.postCallMethod(uploadObj, 'files/add').subscribe(response => {
        if(response.status == true) {
          this.BusyModule.emit({
            busy : false,
            reference_id : referenceId
          });
          data['is_active'] = 1;
          data['com_file_id'] = response.com_file_id;
          if(!referenceId){
            data['com_file_id'] = response.com_file_id;
          }
          data.Location = bucket.getSignedUrl('getObject', getObjParams);
          if(isShowMessage) {
            this.toastrService.success(message, 'Success!');
          }
        } else {
          this.BusyModule.emit({
            busy : false,
            reference_id : referenceId
          });
          this.toastrService.error('Error in Uploading File', 'Error!');
        } 
        return cb(data, file);
      });
    });
  }

  /**
   * 
   * @param file
   * @param basePath 
   * @param attachmentType 
   * @param referenceId 
   * @param referenceObjectId 
   * @param referenceSubId 
   * @param isShowMessage 
   * @param message 
   * @param cb 
   */
  uploadfilePromiseOld(file, basePath, attachmentType : string, referenceId : number, referenceObject : string, referenceSubObject: string, referenceSubId : number, acl: 'private' | 'public-read' | 'public-read-write' | 'authenticated-read' = 'private') {
    return new Promise(async (resolve, reject) => {
      try {
        const bucket      = new S3(
          {
            accessKeyId: environment.AWS_ACCESSKEY,
            secretAccessKey: environment.AWS_SECRETKEY,
            region: environment.AWS_S3_DMS_REGION
          }
        );
        const fileName   = new Date().getTime() + '-' + (file.name.replace(/[^a-zA-Z0-9.]/g, ''));
        const pathOfFile = this.FOLDER + basePath + '/' + fileName;
        const params = {
          Bucket: environment.AWS_S3_DMS_BUCKET,
          Key: pathOfFile,
          Body: file,
          ACL: acl,
          ContentDisposition:'inline',
          ContentType:file.type
        };
        bucket.upload(params,  (err, data) => {
          if (err) {
            return resolve(false);
          } 
          let uploadObj = {};
          uploadObj['file_name']            = fileName;
          uploadObj['original_file_name']   = file.name;
          uploadObj['file_size']            = file.size;
          uploadObj['file_extension']       =  (/[.]/.exec(file.name)) ? /[^.]+$/.exec(file.name) : '',
          uploadObj['aws_doc_key']          = data.Key;
          uploadObj['attachment_type']      = attachmentType;
          uploadObj['reference_id']         = referenceId || 0;  
          uploadObj['reference_object']     = referenceObject;
          uploadObj['reference_sub_object'] = referenceSubObject;
          uploadObj['reference_sub_id']     = referenceSubId;  
          uploadObj['account_id']           = localStorage.account_id;
          this.authService.postCallMethod(uploadObj, 'files/add').subscribe(response => {
            if(response.status) {
              resolve(response.data);
            } else {
              resolve(false);
            } 
          });
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * 
   * @param file
   * @param basePath 
   * @param attachmentType 
   * @param referenceId 
   * @param referenceObjectId 
   * @param referenceSubId 
   * @param isShowMessage 
   * @param message 
   * @param cb 
   */
  uploadFileOnlyPromise(file, basePath, acl: 'private' | 'public-read' | 'public-read-write' | 'authenticated-read' = 'private') {
    return new Promise(async (resolve, reject) => {
      try {
        const bucket      = new S3(
          {
            accessKeyId: environment.AWS_ACCESSKEY,
            secretAccessKey: environment.AWS_SECRETKEY,
            region: environment.AWS_S3_DMS_REGION
          }
        );
        const fileName   = new Date().getTime() + '-' + (file.name.replace(/[^a-zA-Z0-9.]/g, ''));
        const pathOfFile = this.FOLDER + basePath + '/' + fileName;
        const params = {
          Bucket: environment.AWS_S3_DMS_BUCKET,
          Key: pathOfFile,
          Body: file,
          ACL: acl,
          ContentDisposition:`inline; filename=${new Date().getTime()}-${parseInt(localStorage.getItem('user_id'))}`,
          ContentEncoding : 'base64',
          ContentType : file.type
        };
        // console.log('params------------------------>', params);
        bucket.upload(params,  (err, data) => {
          if (err) {
            console.log('File Upload Error------------------------------->', err);
            return resolve(false);
          } 
          return resolve(data.Key);
        });
      } catch (err) {
        reject(err);
      }
    });
  }
 

    /**
   * 
   * @param file
   * @param basePath 
   * @param attachmentType 
   * @param referenceId 
   * @param referenceObjectId 
   * @param referenceSubId 
   * @param isShowMessage 
   * @param message 
   * @param cb 
   */
    uploadfilePromise(file, basePath, attachmentType : string, referenceId : number, referenceObject : string, referenceSubObject: string, referenceSubId : number) {
      return new Promise(async (resolve, reject) => {
        try {
          let accountId = Number(this.storage.getItem('account_id'));
          this.authService.getCallMethod('accounts/getAwsAccountMappingByAccountId/'+accountId).subscribe(
            (res : any) => {
              if(res.data && res.data.length > 0) {
                var awsObj = res.data[0];
                const bucket      = new S3(
                  {
                    accessKeyId: awsObj.aws_access_key,
                    secretAccessKey: awsObj.aws_secret_key,
                    region: awsObj.aws_region
                  }
                );
                const fileName   = new Date().getTime() + '-' + file.name;
                const pathOfFile = this.FOLDER + basePath + '/' + fileName;
                const params = {
                  Bucket: awsObj.aws_bucket,
                  Key: pathOfFile,
                  Body: file,
                  ACL: 'private',
                  ContentDisposition:'inline',
                  ContentType:file.type
                };
                bucket.upload(params,  (err, data) => {
                  if (err) {
                    return resolve(false);
                  } 
                  let uploadObj = {};
                  uploadObj['file_name']            = fileName;
                  uploadObj['original_file_name']   = file.name;
                  uploadObj['file_size']            = file.size;
                  uploadObj['file_extension']       =  (/[.]/.exec(file.name)) ? /[^.]+$/.exec(file.name) : '',
                  uploadObj['aws_doc_key']          = data.Key;
                  uploadObj['attachment_type']      = attachmentType;
                  uploadObj['reference_id']         = referenceId || 0;  
                  uploadObj['reference_object']     = referenceObject;
                  uploadObj['reference_sub_id']     = referenceSubId;  
                  uploadObj['reference_sub_object'] = referenceSubObject
                  uploadObj['account_id']           = localStorage.account_id;
                  this.authService.postCallMethod(uploadObj, 'files/add').subscribe(response => {
                    if(response.status) {
                      resolve(response.data);
                    } else {
                      resolve(false);
                    } 
                  });
                });
              } else { 
                  reject('AWS Bucket Details Missing');
              }
            }
          );
        } catch (err) {
          reject(err);
        }
      });
    }
}