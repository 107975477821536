// Angular
import { Injectable, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs/internal/observable/of';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn : 'root'
})
export class NotificationService {
    isProfileUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor(
        private toastrService: ToastrService
    ) {

    }

    showSuccessMessage(message) {
        this.toastrService.success(message, 'Success!');
    }

    showErrorMessage(message = 'Right time! We are unable to process your request.') {
        this.toastrService.error(message, 'Error!');
    }

    showWarningMessage(message) {
        this.toastrService.warning(message, 'Warning!');
    }

}