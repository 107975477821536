import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AgAuthService } from '../http-server/server/ag-auth.service';
import { ApiService } from '../http-server/server/api.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AgAuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private agAuth: AgAuthService,
        private storage: LocalStorageService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean { 
        if(environment.isMockEnabled) {
            // if(!!this.storage.getItem('is_locked')) {
            //     this.router.navigate(['/auth/lock-screen'], { queryParams : { returnUrl : location.pathname } });
            //     return false;
            // }

            // if(!!this.storage.getItem('is_two_factor_authentication')) {
            //     this.router.navigate(['/auth/is_two_factor_authentication'], { queryParams : { returnUrl : location.pathname } });
            //     return false;
            // }

            // if(this.storage.getItem('authToken') && !!this.storage.getItem('is_pin_generate') && !!!this.storage.getItem('skip_pin_generation')) {
            //     this.router.navigate(['/auth/generate-pin']);
            //     return false;
            // }
            if(!this.storage.getItem('authToken')) {
                if(location.pathname) {
                    this.router.navigate(['/auth/login'], { queryParams : { returnUrl : location.pathname } });
                } else {
                    this.router.navigate(['/auth/login']);
                }
                return false;
            }
        }

        return new Promise(res => {
            if(environment.isMockEnabled) {
                this.agAuth.isAuthenticated().subscribe(active => {
                    if(active) {
                        res(true);
                    } else {
                        let fullName = this.storage.getItem('fullname') || '';
                        let isEnableWebAuthn = this.storage.getItem('is_enable_webauth');
                        let userId = this.storage.getItem('user_id');
                        this.storage.clear();
                        if(isEnableWebAuthn) {
                            this.storage.setItem('is_enable_webauthn', isEnableWebAuthn);
                            this.storage.setItem('user_id', userId);
                            this.storage.setItem('fullname', fullName);
                        }
                        if(location.pathname) {
                            this.router.navigate(['/auth/login'], { queryParams : { returnUrl : location.pathname } });
                        } else {
                            this.router.navigate(['/auth/login']);
                        }
                        res(false);
                    }
                }, (error) => {
                        if(location.pathname) {
                            this.router.navigate(['/auth/login'], { queryParams : { returnUrl : location.pathname } });
                        } else {
                            this.router.navigate(['/auth/login']);
                        }
                        res(false);
                });
            } else {
                res(true);
            }
        });

    }

}