import { Injectable } from '@angular/core';
import { LocalStorageService } from "./local-storage.service";
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root'
})
export class IosPushNotificationService {

  constructor(private storage: LocalStorageService,
    private platform : Platform) { }


    allowNotification() {
      console.log("IosPushNotificationService");
      console.log("allowNotification");
      console.log("this.storage.getItem('account_id') :: ", this.storage.getItem('account_id'));
      console.log("this.storage.getItem('user_id') :: ", this.storage.getItem('user_id'));
      console.log("this.storage.getItem('reference_id') :: ", this.storage.getItem('reference_id'));
      console.log("this.storage.getItem('reference_object') :: ", this.storage.getItem('reference_object'));
      //function invokeNativeCode() {
        let isIos = this.platform.IOS;
        if(isIos) {
          if (window.webkit && window.webkit.messageHandlers['pushPermissionRequest']) {
            console.log("Push permission given ::");
            const messagePayload = {
              action: 'pushPermissionRequest',
              account_id: this.storage.getItem('account_id') || 0,
              user_id: this.storage.getItem('user_id') || 0,          // Replace with the actual user ID
              reference_id: this.storage.getItem('reference_id') || 0, // Replace with the actual reference ID
              reference_object: this.storage.getItem('reference_object') || 0 // Replace with the actual reference object
          };
          (window as any).window.webkit.messageHandlers['pushPermissionRequest'].postMessage(messagePayload);
          } else {
            console.error('push-permission-request handler is not available.');
          }
        }
    }

    pushToken() {
      console.log("pushToken");
      let isIos = this.platform.IOS;
        if(isIos) {
          if (window.webkit && window.webkit.messageHandlers['pushToken']) {
            console.log("pushToken given ::");
            const messagePayload = {
              action: 'pushToken',
              account_id: this.storage.getItem('account_id') || 0,
              user_id: this.storage.getItem('user_id') || 0,          // Replace with the actual user ID
              reference_id: this.storage.getItem('reference_id') || 0, // Replace with the actual reference ID
              reference_object: this.storage.getItem('reference_object') || 0 // Replace with the actual reference object
          };
          (window as any).window.webkit.messageHandlers['pushToken'].postMessage(messagePayload);
          } else {
            console.error('pushToken handler is not available.');
          }
        }
      }
}
