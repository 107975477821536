export const allPermissions: string[] = [
	"SUPER_ADMIN",
	"employee_create",
	"employee_update",
	"employee_view",
	"employee_lists",
	"role_create",
	"role_edit",
	"role_view",
	"role_list",
	"role_enable_disable",
	"employee_create",
	"employee_edit",
	"employee_view",
	"employee_reset_password",
	"employee_list",
	"employee_enable_disable",
	"chapter_list",
	"chapter_create",
	"chapter_edit",
	"chapter_view",
	"chapter_enable_disable",
	"zone_list",
	"zone_create",
	"zone_edit",
	"zone_view",
	"zone_enable_disable",
	"membership_type_list",
	"membership_type_create",
	"membership_type_edit",
	"membership_type_view",
	"membership_type_enable_disable",
	"subscription_plan_list",
	"subscription_plan_create",
	"subscription_plan_edit",
	"subscription_plan_view",
	"subscription_plan_enable_disable"
];
