/** Angular */
import { Injectable } from '@angular/core';

@Injectable()
export class TypesUtilsService {

	padNumber(value: number) {
		if (this.isNumber(value)) {
			return `0${value}`.slice(-2);
		} else {
			return '';
		}
	}

	isNumber(value: any): boolean {
		return !isNaN(this.toInteger(value));
  }
  
	toInteger(value: any): number {
		return parseInt(`${value}`, 10);
  }
  
  isDate(value: any) : boolean {
    return value && !isNaN(new Date(value).getTime());
  }

  toDate(value: any) : Date {
		if (value) {
			return !isNaN(new Date(value).getTime()) ? value : (this.isNumber(value) ? new Date(parseInt(value)) : null);
		}
    return null;
  }
}