import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { Subscription } from "rxjs";

@Directive({
  selector: '[responsiveDisplay]'
})
export class ResponsiveDisplayDirective implements OnDestroy, OnInit {
  private subscriptions: Subscription[] = [];
  private _responsiveDisplay: string[] = [];
  @Input() 
  set responsiveDisplay(value: string[]) {
    this._responsiveDisplay = value;
    this.initiate();
  }
  constructor(
    private templateReference: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private breakpointObserver: BreakpointObserver,
    private changes: ChangeDetectorRef
  ) {
    
  }

  ngOnInit() {
    
  }

  ngAfterViewInit(): void {
    const observerSubscription = this.breakpointObserver
    .observe(['(max-width: 768px)', '(min-width:769px) and (max-width:1124px)', '(min-width:1125px)'])
    .subscribe((state: BreakpointState) => {
      this.viewContainerRef.remove();
      if (state['breakpoints']['(min-width:1125px)'] && this._responsiveDisplay.includes('desktop')) {
        this.showHideHost(true);
      } else if (state['breakpoints']['(min-width:769px) and (max-width:1124px)'] && this._responsiveDisplay.includes('tablet')) {
        this.showHideHost(true);
      } else if (state['breakpoints']['(max-width: 768px)'] && this._responsiveDisplay.includes('mobile')) {
        this.showHideHost(true);
      } else {
        this.showHideHost(false);
      }
      this.changes.detectChanges();
    });
    this.subscriptions.push(observerSubscription);
  }

  private showHideHost = (matches: boolean) => {
    if (matches && !this.viewContainerRef.length) {
      this.viewContainerRef.createEmbeddedView(this.templateReference);
    } else if (!matches && this.viewContainerRef.length) {
      this.viewContainerRef.clear();
    }
  }

  initiate() {
    if (this.breakpointObserver.isMatched('(min-width:1125px)') && this._responsiveDisplay.includes('desktop')) {
      this.showHideHost(true);
    } else if (this.breakpointObserver.isMatched('(min-width:769px) and (max-width:1124px)') && this._responsiveDisplay.includes('tablet')) {
      this.showHideHost(true);
    } else if (this.breakpointObserver.isMatched('(max-width: 768px)') && this._responsiveDisplay.includes('mobile')) {
      this.showHideHost(true);
    } else {
      this.showHideHost(false);
    }
    this.changes.detectChanges();
  }

  ngOnDestroy(): void {
    this.breakpointObserver.ngOnDestroy();
    this.subscriptions.forEach(d => d.unsubscribe());
  }
}
