import { Injectable } from '@angular/core';
import { NgxRolesService, NgxPermissionsService } from 'ngx-permissions';
import { mergeMap, filter } from 'rxjs/operators';
import { AgAuthService } from '../http-server/server/ag-auth.service';

@Injectable({
	providedIn : 'root'
})
export class AclService {

	constructor(
		private permService: NgxPermissionsService,
		private agAuth : AgAuthService
	) {
		
	}

	setPermissions() {
		this.removePermissions();
		const perm = this.agAuth.PERMISSION_DATA;
    	this.permService.loadPermissions(perm);
	}

	removePermissions() {
		this.permService.flushPermissions();
	}
}
