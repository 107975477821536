import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { systemDeviceGuardGuard } from './ag-core/_agaram/ag-core/guards/system-device-guard.guard';
import { mobileDeviceGuard } from './ag-core/_agaram/ag-core/guards/mobile-device.guard';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { AgAuthService, LocalStorageService } from './ag-core/_agaram/ag-core';

@Component({
  selector: 'app-redirect',
  template: ``
})
export class RedirectHandlerComponent implements OnInit {

  constructor(
    private router: Router,
    private viewPortRuler : ViewportRuler,
    private storage : LocalStorageService,
    private agAuth : AgAuthService
  ) {}

  ngOnInit(): void {
    let isSystem = this.viewPortRuler.getViewportSize().width >= 510;
    let isMobile = this.viewPortRuler.getViewportSize().width < 510;
    const url = this.router.url;
    const urls = url.split('/');
    let param = urls[urls.length - 1];
    if(isSystem) {
        if(url.includes('/qe')) {
            let authToken = this.storage.getItem('authToken');
            if(authToken) {
                this.agAuth.isAuthenticated().subscribe(
                    active => {
                        if(active) {
                            this.router.navigate(['/meeting/view', param]);
                        } else {
                            this.router.navigate(['/p/na-event', param]);
                        }
                    }
                );
            } else {
                this.router.navigate(['/p/na-event', param]);
            }
        } else if(url.includes('/if')) {
            this.router.navigate(['/referral/list'], { queryParams : { create : true } });
        } else if(url.includes('/lv')) {
            this.router.navigate(['/lead/view', param]);
        } else if(url.includes('/ev/')) {
            this.router.navigate(['/emart/detail', param]);
        } else if(url.includes('/su')) {
            this.router.navigate(['/payment/list'], { queryParams : { inv_no : param } });
        } else if(url.includes('/iv')) {
            this.router.navigate(['/payment/list'], { queryParams : { pmt_no : param } });
        } else if(url.includes('/cm')) {
            this.router.navigate(['/cubelabs/meeting', param]);
        } else {
            this.router.navigate(['/error/404']);
        }
    } else if(isMobile) {
        if(url.includes('/qe')) {
            let authToken = this.storage.getItem('authToken');
            if(authToken) {
                this.agAuth.isAuthenticated().subscribe(
                    active => {
                        if(active) {
                            this.router.navigate(['/meetings/event-detail', param]);
                        } else {
                            this.router.navigate(['/p/na-event', param]);
                        }
                    }
                );
            } else {
                this.router.navigate(['/p/na-event', param]);
            }
        } else if(url.includes('/if')) {
            this.router.navigate(['/referrals/create']);
        } else if(url.includes('/lv')) {
            this.router.navigate(['/lead/view', param]);
        } else if(url.includes('/ev/')) {
            this.router.navigate(['/emart/detail', param]);
        } else if(url.includes('/su')) {
            this.router.navigate(['/payments/list'], { queryParams : { inv_no : param } });
        } else if(url.includes('/iv')) {
            this.router.navigate(['/payments/list'], { queryParams : { pmt_no : param } });
        } else if(url.includes('/cm')) {
            this.router.navigate(['/cubelab/meeting-detail', param]);
        } else {
            this.router.navigate(['/menu']);
        }
    } else {
        this.router.navigate(['/error/404']);
    }
  }
}