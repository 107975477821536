import { ViewportRuler } from '@angular/cdk/scrolling';
import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';

export const mobileDeviceGuard: CanMatchFn = (route, segments) => {

  const viewPortRuler = inject(ViewportRuler);
  return viewPortRuler.getViewportSize().width < 600;

//   const viewPortRuler = inject(ViewportRuler);
//   const viewportWidth = viewPortRuler.getViewportSize().width;
// const pixelRatio = window.devicePixelRatio;
// return viewportWidth < 510 && pixelRatio > 2;

};
