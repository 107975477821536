import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LocalStorageService } from '../../ag-core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private previousUrl: string = '';
  private currentUrl: string = '';
  unstorableUrls : string[] = [
    'create',
    'edit',
    'checkout',
    'cubelab/request',
    'auth/'
  ];

  constructor(
    private router: Router,
    private storage : LocalStorageService
  ) {
    this.currentUrl = this.router.url;
    this.setUrl();
  }

  setUrl() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
        let previous = this.storage.getItem('previousUrl') || '';
        this.previousUrl = this.currentUrl == '/' ? previous == '/' ? event.url : previous : this.currentUrl;
        this.currentUrl = event.url;
        this.storage.setItem('previousUrl', this.previousUrl);
    });
  }

  getPreviousUrl(): string {
      return this.previousUrl;
  }
}
