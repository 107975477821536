import { EventEmitter, Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GeoLocationService {
  isSupportGeoLocation: boolean = false;

  errorStatus: string = '';

  locatePosition: EventEmitter<any> = new EventEmitter();
  
  constructor(
    private sanitizer: DomSanitizer
  ) {
    this.isSupportGeoLocation = navigator.geolocation ? true : false;
  }

  getGoogleMapLink(latitude: number, longitude: number) {
    if (latitude && longitude) {
      return this.sanitizer.bypassSecurityTrustUrl(`https://maps.google.com/maps?q=loc:${latitude},${longitude}`);
    } else {
      return null;
    }
  }

  showInMap(latitude: number, longitude: number) {
    if (latitude && longitude) {
      var latlon = latitude + "," + longitude;
      var img_url = "https://maps.googleapis.com/maps/api/staticmap?center="+latlon+"&zoom=14&size=400x300&sensor=false&key=";    
      return this.sanitizer.bypassSecurityTrustUrl(img_url);
    } else {
      return null;
    }
  }

  getLocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
            this.locatePosition.emit(position);
        }, error => {
            this.locatePosition.emit({error: error});
            switch(error.code) {
                case error.PERMISSION_DENIED:
                    this.errorStatus = 'User denied the request for geolocation.';
                    return this.errorStatus;
                case error.POSITION_UNAVAILABLE:
                    this.errorStatus = 'Location information is currently unavailable.';
                    return this.errorStatus;
                case error.TIMEOUT:
                    this.errorStatus = 'Request for user location timed out.';
                    return this.errorStatus;
                default:
                    console.log(error);
                    this.errorStatus = "An unknown error occurred";
                    return this.errorStatus;
            }
        }, 
        {
            enableHighAccuracy: true
        });
    } else {
      console.log("Geo Location not supported by browser");
    }
  }

  getLocationPromise() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        const options = {
          enableHighAccuracy: false, // Adjust as needed
          timeout: 10000, // Adjust timeout as needed
        }
        navigator.geolocation.getCurrentPosition(
          position => {
            return resolve(position);
          }, error => {
              if(error.code == 1) {
                this.errorStatus = 'User denied the request for geolocation.';
              } else if(error.code == 2) {
                this.errorStatus = 'Location information is currently unavailable.';
              } else if(error.code == 3) {
                this.errorStatus = 'Request for user location timed out.';
              } else {
                this.errorStatus = "An unknown error occurred";
              }
              return reject(this.errorStatus);
          }, 
          options
        );
      } else {
          return reject("Geo Location not supported by browser");
      }
    });
  }

  //function that retrieves the position
  showPosition(position) {
    var location = {
      longitude: position.coords.longitude,
      latitude: position.coords.latitude,
    };
    console.log(location);
  }
}
