import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CameraPermissionService {
  
  private permissionStatus: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public permissionStatus$: Observable<string> = this.permissionStatus.asObservable();  

  constructor() { }

  async checkCameraPermission(): Promise<string> {
    if (!navigator.permissions) {
      return 'The Permissions API is not supported in your browser.';
    }

    try {
      const permissionStatus = await navigator.permissions.query({ name: 'camera' as PermissionName });
      return permissionStatus.state;
    } catch (error) {
      return 'Error checking camera permission: ' + error.message;
    }
  }

  async requestCameraAccess(): Promise<void> {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach(track => track.stop());
      this.permissionStatus.next('granted');
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        this.permissionStatus.next('denied');
      } else if (error.name === 'NotFoundError') {
        this.permissionStatus.next('not_found');
      } else {
        this.permissionStatus.next('error: ' + error.message);
      }
    }
  }

  requestCameraAccessPromise() {
    return new Promise(async (resolve, reject) => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        return resolve('granted');
      } catch (error) {
          if (error.name === 'NotAllowedError') {
            return resolve('denied');
          } else if (error.name === 'NotFoundError') {
            return resolve('not_found');
          } else {
            return resolve('error: ' + error.message);
          }
      }
    });
  }
}
