// Angular
import { Injectable, EventEmitter } from '@angular/core';
// RxJS
import { fromEvent, Observable } from 'rxjs';


@Injectable({
	providedIn : 'root'
})
export class InternetConnectionService {
	onlineEvent: Observable<Event>;
	offlineEvent: Observable<Event>;
	isInternetAvailable = new EventEmitter<any>();

	// Public properties
	constructor() {
		this.init();
	}

	init() {
		if (!navigator.onLine) {
			this.isInternetAvailable.emit(false);
		}

		this.onlineEvent = fromEvent(window, 'online');
		this.offlineEvent = fromEvent(window, 'offline');

		this.onlineEvent.subscribe(e => {
			/* Back To Online */
			this.isInternetAvailable.emit(true);
		});

		this.offlineEvent.subscribe(e => {
			/* Connection lost! You are not connected to internet */
			this.isInternetAvailable.emit(false);
		});
	}
}
