import { Directive, OnInit, ElementRef, TemplateRef, ViewContainerRef, Input, HostListener } from '@angular/core';
import { AgAuthService } from '../http-server/server/ag-auth.service';


@Directive({
  selector: '[stickyElement]'
})
export class StickyElementDirective implements OnInit {
  stickyMarginTop: number = 0;

  @HostListener('window:resize', ['$event'])
	onResize() {
		this.updateStickyPosition();
	}

	@HostListener('window:scroll', ['$event'])
	onScroll() {
		this.updateStickyPosition();
	}

  constructor(
    private element: ElementRef
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.updateStickyPosition();
  }

  updateStickyPosition() {
    Promise.resolve(null).then(() => {
      // get boundary top margin for sticky header
      const headerElement = <HTMLElement>document.querySelector('.header');
      const subheaderElement = <HTMLElement>document.querySelector('.kt-subheader');
      const headerMobileElement = <HTMLElement>document.querySelector('.header-mobile');
      const portletHeadElement = <HTMLElement>document.querySelector('.card-header');
      let height = 0;
      if (headerElement != null || headerMobileElement != null) {
        // normal fixed header
        if (document.body.classList.contains('header-fixed')) {
          height += headerElement?.offsetHeight;
        }
        if (document.body.classList.contains('header-mobile-fixed')) {
          height += headerMobileElement?.offsetHeight;
        }
        if (document.body.classList.contains('kt-subheader--fixed')) {
          height += subheaderElement.offsetHeight;
        }
        height += portletHeadElement.offsetHeight;
      }
      this.stickyMarginTop = height;
      this.element.nativeElement.style.top = this.stickyMarginTop + 'px'; 
      this.element.nativeElement.classList.add('sticky-container');
    });
	}

}
