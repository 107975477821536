import { Directive, OnInit, ElementRef, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AgAuthService } from '../http-server/server/ag-auth.service';


@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {
  private permissions : string[] = [];

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private auth: AgAuthService
  ) {
  }

  ngOnInit() {
  }

  @Input()
  set hasPermission(val) {
    this.permissions = val;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
        this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = false;
    if (environment.isMockEnabled) {
      if (this.permissions && this.auth.PERMISSION_DATA && this.auth.PERMISSION_DATA.length > 0) {
        this.permissions.forEach((data) => {
          if (this.auth.PERMISSION_DATA.indexOf(data) !== -1) {
            hasPermission = true;
          }
        });
      }
    } else {
      hasPermission = true;
    }
    return hasPermission;
  }
}
