import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { environment } from '../../../../../../environments/environment';
import { detectIncognito } from 'detectincognitojs';
import { LoadingService } from '../../../ag-shared/services/loading.service';

function _window(): any {
	return window;
}

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	isRefreshPage: Subject<string> = new Subject<string>();
	// BASE_URL: string = environment.API_BASE_URL;
	isIgnitoMode: boolean = false;
	browserName: string = '';
	constructor(
		private http: HttpClient,
		private loadingService: LoadingService
	) { 
		detectIncognito().then((result) => {
			this.isIgnitoMode = result.isPrivate;
			this.browserName = result.browserName;
		});
	}

	get nativeWindow(): any {
		return _window();
	}

	public postCallMethod(obj={}, serviceCallName='', isauth = true): Observable<any> {
		let httpOptions;
		let BASE_URL = environment.API_BASE_URL;
		if (isauth) {
			httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'x-ag-date': new Date().toISOString(),
					'x-app-version': environment.appVersion,
					'x-auth-token': JSON.parse(localStorage.getItem('authToken') || ''),
					'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
					'browser-name': this.browserName
				})
			};
			BASE_URL = environment.API_BASE_URL;
		} else {
			httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'x-ag-date': new Date().toISOString(),
					'x-app-version': environment.appVersion,
					'Access-Control-Allow-Origin': '*',
					'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
					'browser-name': this.browserName
				})
			};
			BASE_URL = environment.REST_API_BASE_URL;
		}
		const url: string = BASE_URL + '/' + serviceCallName;
		if (environment.isMockEnabled) {
			return this.http.post<any>(url, obj, httpOptions).pipe(shareReplay(1, 5000));
		} else {
			return of({status: false});
		}
	}

	public getCallMethod(serviceCallName = '', isauth = true): Observable<any> {
		let httpOptions;
		let BASE_URL = environment.API_BASE_URL;
		if (isauth) {
			httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'x-ag-date': new Date().toISOString(),
					'x-app-version': environment.appVersion,
					'x-auth-token': JSON.parse(localStorage.getItem('authToken') || ''),
					'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
					'browser-name': this.browserName
				})
			};
			BASE_URL = environment.API_BASE_URL;
		} else {
			httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'x-ag-date': new Date().toISOString(),
					'x-app-version': environment.appVersion,
					'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
					'browser-name': this.browserName
				})
			};
			BASE_URL = environment.REST_API_BASE_URL;
		}
		const url: string = BASE_URL + '/' + serviceCallName;
		if (environment.isMockEnabled) {
			return this.http.get<any>(url, httpOptions).pipe(shareReplay(1, 5000));
		} else {
			return of({status: false});
		}
	}

	public rawPostCallMethod(obj={}, serviceCallName='', isauth = true, responseType = 'arraybuffer'): Observable<any> {
		let httpOptions:any;
		let BASE_URL = environment.API_BASE_URL;
		if (isauth) {
			httpOptions = {
				responseType: responseType || 'arraybuffer',
				headers: new HttpHeaders({
					'Access-Control-Allow-Origin': '*',
					'x-app-version': environment.appVersion,
					'x-ag-date': new Date().toISOString(),
					'x-auth-token': JSON.parse(localStorage.getItem('authToken') || ''),
					'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
					'browser-name': this.browserName
				})
			};
			BASE_URL = environment.API_BASE_URL;
		} else {
			httpOptions = {
				responseType: responseType || 'arraybuffer',
				headers: new HttpHeaders({
					'Access-Control-Allow-Origin': '*',
					'x-app-version': environment.appVersion,
					'x-ag-date': new Date().toISOString(),
					'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
					'browser-name': this.browserName
				})
			};
			BASE_URL = environment.REST_API_BASE_URL;
		}
		const url: string = BASE_URL + '/' + serviceCallName;
		if (environment.isMockEnabled) {
			return this.http.post<any>(url, obj, httpOptions);
		} else {
			return of({status: false});
		}
	}

	public rawGetCallMethod(serviceCallName='', isauth = true): Observable<any> {
		let httpOptions:any;
		let BASE_URL = environment.API_BASE_URL;
		if (isauth) {
			httpOptions = {
				responseType: 'arraybuffer',
				headers: new HttpHeaders({
					'Access-Control-Allow-Origin': '*',
					'x-ag-date': new Date().toISOString(),
					'x-app-version': environment.appVersion,
					'x-auth-token': JSON.parse(localStorage.getItem('authToken') || ''),
					'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
					'browser-name': this.browserName
				})
			};
			BASE_URL = environment.API_BASE_URL;
		} else {
			httpOptions = {
				responseType: 'arraybuffer',
				headers: new HttpHeaders({
					'Access-Control-Allow-Origin': '*',
					'x-app-version': environment.appVersion,
					'x-ag-date': new Date().toISOString(),
					'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
					'browser-name': this.browserName
				})
			};
			BASE_URL = environment.REST_API_BASE_URL;
		}
		const url: string = BASE_URL + '/' + serviceCallName;
		if (environment.isMockEnabled) {
			return this.http.get<any>(url, httpOptions);
		} else {
			return of({status: false});
		}
	}

	public deleteCallMethod(serviceCallName='', isauth = true): Observable<any> {
		let httpOptions;
		let BASE_URL = environment.API_BASE_URL;
		if (isauth) {
			httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'x-ag-date': new Date().toISOString(),
					'x-app-version': environment.appVersion,
					'x-auth-token': JSON.parse(localStorage.getItem('authToken') || ''),
					'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
					'browser-name': this.browserName
				})
			};
			BASE_URL = environment.API_BASE_URL;
		} else {
			httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'x-ag-date': new Date().toISOString(),
					'x-app-version': environment.appVersion,
					'Access-Control-Allow-Origin': '*',
					'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
					'browser-name': this.browserName
				})
			};
			BASE_URL = environment.REST_API_BASE_URL;
		}
		const url: string = BASE_URL + '/' + serviceCallName;
		if (environment.isMockEnabled) {
			return this.http.delete<any>(url, httpOptions).pipe(shareReplay(1, 5000));
		} else {
			return of({status: false});
		}
	}

	public downloadMemberVCard(obj={}, serviceCallName, isauth = true) : Observable<Blob> {
		let BASE_URL = environment.API_BASE_URL;
		const url: string = BASE_URL + '/' + serviceCallName;
		let headers = new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-ag-date': new Date().toISOString(),
				'x-app-version': environment.appVersion,
				'x-auth-token': JSON.parse(localStorage.getItem('authToken') || ''),
				'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
				'browser-name': this.browserName
		});
		return this.http.post(url, obj, { responseType : 'blob', headers : headers });
	};

	public putCallMethod(obj:{}, serviceCallName='', isauth = true): Observable<any> {
		let httpOptions;
		let BASE_URL = environment.API_BASE_URL;
		if (isauth) {
			httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'x-ag-date': new Date().toISOString(),
					'x-app-version': environment.appVersion,
					'x-auth-token': JSON.parse(localStorage.getItem('authToken') || ''),
					'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
					'browser-name': this.browserName
				})
			};
			BASE_URL = environment.API_BASE_URL;
		} else {
			httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'x-ag-date': new Date().toISOString(),
					'x-app-version': environment.appVersion,
					'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
					'browser-name': this.browserName
				})
			};
			BASE_URL = environment.REST_API_BASE_URL;
		}
		const url: string = BASE_URL + '/' + serviceCallName;
		if (environment.isMockEnabled) {
			return this.http.put<any>(url, obj, httpOptions).pipe(shareReplay(1, 5000));
		} else {
			return of({status: false});
		}
	}

	/**
	 * Get Method Service call Accept all type of response
	 * @param serviceCallName
	 * @param applicationType
	 * @param filename
	 * @isauth true
	*/

	public getCallMethodDownloadUrl(serviceCallName='', applicationType='', filename='', isauth = true) {
		let headers;
		let BASE_URL = environment.API_BASE_URL;
		if (isauth) {
			headers = new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-ag-date': new Date().toISOString(),
				'x-app-version': environment.appVersion,
				'x-auth-token': JSON.parse(localStorage.getItem('authToken') || ''),
				'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
				'browser-name': this.browserName
			});
			BASE_URL = environment.API_BASE_URL;
		} else {
			headers = new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-app-version': environment.appVersion,
				'x-ag-date': new Date().toISOString(),
				'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
				'browser-name': this.browserName
			});
			BASE_URL = environment.REST_API_BASE_URL;
		}
		const url: string = BASE_URL + '/' + serviceCallName;
		return this.http.get(url, { responseType: 'arraybuffer', headers: headers })
			.subscribe(
				response => {
					this.downLoadFile(response, applicationType, filename)
				}
			);
	}

	/**
	 * Post Method Service call Accept all type of response
	 * @param serviceCallName
	 * @param obj
	 * @param applicationType
	 * @param filename
	 * @isauth true
	*/

	public postImageCallMethod(obj={}, serviceCallName='', BASE_URL: string = environment.API_BASE_URL): Observable<any> {

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-app-version': environment.appVersion,
				'x-ag-date': new Date().toISOString(),
				'x-auth-token': JSON.parse(localStorage.getItem('authToken') || ''),
				'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
				'browser-name': this.browserName
			})
		};
		const url: string = BASE_URL + '/' + serviceCallName;
		return this.http.post<any>(url, obj, httpOptions).pipe(shareReplay(1, 5000));

	}

	public postCallMethodDownloadUrl(obj={}, serviceCallName='', applicationType='', filename='', isauth = true) {
		let headers;
		let BASE_URL = environment.API_BASE_URL;
		if (isauth) {
			headers = new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-ag-date': new Date().toISOString(),
				'x-app-version': environment.appVersion,
				'x-auth-token': JSON.parse(localStorage.getItem('authToken') || ''),
				'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
				'browser-name': this.browserName
			});
			BASE_URL = environment.API_BASE_URL;
		} else {
			headers = new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-app-version': environment.appVersion,
				'x-ag-date': new Date().toISOString(),
				'is-ignito-mode': this.isIgnitoMode ? 'YES' : 'NO',
				'browser-name': this.browserName
			});
			BASE_URL = environment.REST_API_BASE_URL;
		}
		const url: string = BASE_URL + '/' + serviceCallName;
		this.loadingService.show();
		return this.http.post(url, obj, { responseType: 'arraybuffer', headers: headers })
			.subscribe(
				response => {
					this.loadingService.hide();
					this.downLoadFile(response, applicationType, filename)
				}
			);
	}

	public gspApiGetCallMethod(serviceCallName) : Observable<any> {
		let httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			})
		};
		const url : string = environment.GSP_API_BASE_URL + '/' + serviceCallName;
		return this.http.get<any>(url, httpOptions).pipe(shareReplay(1));
	}

	public gspApiPostCallMethod(obj, serviceCallName) : Observable<any> {
		let httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-auth-token': JSON.parse(localStorage.getItem('authToken') || '')
			})
		};
		const url : string = environment.GSP_API_BASE_URL + '/' + serviceCallName;
		return this.http.post<any>(url, obj, httpOptions).pipe(shareReplay(1));
	}

	public gspApiPutCallMethod(obj, serviceCallName): Observable<any> {

		let httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'x-auth-token': JSON.parse(localStorage.getItem('authToken') || '')
			})
		}
		const url: string = environment.GSP_API_BASE_URL + '/' + serviceCallName;
		return this.http.put<any>(url, obj, httpOptions).pipe(shareReplay(1));
	}

	public gspApiPostCallMethodDownloadUrl(obj, serviceCallName, applicationType, filename) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		});
		const url: string = environment.GSP_API_BASE_URL + '/' + serviceCallName;
		return this.http.post(url, obj, { responseType: 'arraybuffer', headers: headers })
			.subscribe(
				response => {
					this.downLoadFile(response, applicationType, filename)
				}
			);
	}

	/**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
	 * @param filename - download file name
     */

	downLoadFile(data: any, type: string, filename: string) {
		var blob = new Blob([data], { type: type });
		const fileName = filename.replace(/\./g, '');
		saveAs(blob, fileName);
	}

	logOut() {
		localStorage.clear();
		sessionStorage.clear();
		location.reload();
	}
}
