import { Pipe } from "@angular/core";

@Pipe({
  name: `humanize`
})

export class HumanizePipe {
  transform(value: any, ...args: any[]): any {
    if (value && typeof value === 'string') {
      value = value
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_\s]+/g, ' ')
      .replace(/^[a-z]/, function(m) { return m.toUpperCase(); });
      return value;
    }
    return value;
  }
}