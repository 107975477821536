import { EventEmitter, Injectable } from '@angular/core';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UploadFileV3Service {
  BusyModule = new EventEmitter<any>();
  FOLDER = '';
  constructor(
    private authService: ApiService
  ) { }

  uploadfilePromiseV3(file, basePath, attachmentType : string, referenceId : number, referenceObject : string, referenceSubId : number, acl: 'private' | 'public-read' | 'public-read-write' | 'authenticated-read' = 'private') {
    return new Promise(async (resolve, reject) => {
      try {
        const bucket = new S3Client({
          credentials: {
            accessKeyId: environment.AWS_ACCESSKEY,
            secretAccessKey: environment.AWS_SECRETKEY
          },
          region: environment.AWS_S3_DMS_REGION
        });
        const fileName   = new Date().getTime() + '-' + (file.name.replace(/[^a-zA-Z0-9.]/g, ''));
        const pathOfFile = this.FOLDER + basePath + '/' + fileName;
        const params = {
          Bucket: environment.AWS_S3_DMS_BUCKET,
          Key: pathOfFile,
          Body: file,
          ACL: acl,
          ContentDisposition:'inline',
          ContentType:file.type
        };
        let command = new PutObjectCommand(params);
        bucket.send(command).then(data => {
          let uploadObj = {};
          uploadObj['file_name']            = fileName;
          uploadObj['original_file_name']   = file.name;
          uploadObj['file_size']            = file.size;
          uploadObj['file_extension']       =  (/[.]/.exec(file.name)) ? /[^.]+$/.exec(file.name) : '',
          uploadObj['aws_doc_key']          = params.Key;
          uploadObj['attachment_type']      = attachmentType;
          uploadObj['reference_id']         = referenceId || 0;  
          uploadObj['reference_object']     = referenceObject;
          uploadObj['reference_sub_id']     = referenceSubId;  
          uploadObj['account_id']           = localStorage.account_id;
          this.authService.postCallMethod(uploadObj, 'files/add').subscribe(response => {
            if(response.status) {
              resolve(response.data);
            } else {
              resolve(false);
            } 
          });
        }).catch(err => {
          console.log('Upload File Error', err);
          return reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

}
