import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AgAuthService } from '../http-server/server/ag-auth.service';
import { ApiService } from '../http-server/server/api.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AgLoginGuard implements CanActivate {

    constructor(
        private router: Router,
        private agAuth: AgAuthService,
        private storage : LocalStorageService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if(!!this.storage.getItem('is_locked')) {
            return true;
        } else if(!!this.storage.getItem('is_two_factor_authentication')) {
            return true;
        } else if(this.storage.getItem('authToken') && !!!this.storage.getItem('is_pin_generate') && !!!this.storage.getItem('skip_pin_generation')) {
            return true;
        } else if(this.agAuth.isAuth && this.storage.getItem('authToken')) {
            this.router.navigate(['/']);
            return false;
        }
        return true;

    }

}