import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitCharacters'
})
export class LimitCharactersPipe implements PipeTransform {

  transform(value : string, limit : number): string {
    if(value) {
      if(value.length > limit) {
        return `${value.substring(0, limit)}...`;
      } else {
          return value;
      }
    } else {
        return '';
    }
  }

}
