import { Routes, Router, Scroll, provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling, withRouterConfig, RouterModule } from '@angular/router';
import { AgAuthGuard } from './ag-core/_agaram/ag-core';
import { AgLoginGuard } from './ag-core/_agaram/ag-core/guards/ag-login.guard';
import { mobileDeviceGuard } from './ag-core/_agaram/ag-core/guards/mobile-device.guard';
import { systemDeviceGuardGuard } from './ag-core/_agaram/ag-core/guards/system-device-guard.guard';
import { RedirectHandlerComponent } from './redirect-handler.component';

const appRoutes : Routes = [
    {
      path: 'auth',
      canMatch : [systemDeviceGuardGuard],
      canActivate : [AgLoginGuard],
      loadChildren : () => import('./ag-modules/auth/auth.route').then(r => r.authRoutes)
    },
    {
      path: 'auth',
      canMatch : [mobileDeviceGuard],
      canActivate : [AgLoginGuard],
      loadChildren : () => import('./ag-mobile-layout/components/auth/auth.route').then(r => r.authRoutes)
    },
    {
      path: 'ysc',
      canMatch : [mobileDeviceGuard],
      // canActivate : [AgLoginGuard],
      loadComponent : () => import('./ag-yescon-mobile/ag-yescon-mobile.component').then(c => c.AgYesconMobileComponent),
      loadChildren : () => import('./pages/yescon-routing').then(r => r.yesconRouting )
    },
    {
      path: 'p',
      canMatch : [systemDeviceGuardGuard],
      loadChildren: () => import('./ag-portal/ag-portal.route').then(r => r.agPortalRoutes),
    },
    {
      path: 'p',
      canMatch : [mobileDeviceGuard],
      loadChildren: () => import('./ag-portal-mobile/ag-portal-mobile.routing').then(r => r.agPortalMobileRoutes),
    },
    {
      path: 'offline-menu',
      canMatch : [mobileDeviceGuard],
      loadComponent : () => import('../app/ag-mobile-layout/components/menu/components/menu/menu.component').then(c => c.MenuComponent),
    },
    {
      path: 'payment-confirmation',
      canMatch : [mobileDeviceGuard],
      loadComponent : () => import('./ag-core/_agaram/ag-shared/components/payment-confirmation/payment-confirmation.component').then(c => c.PaymentConfirmationComponent),
    },
    {
      path: '',
      canMatch : [mobileDeviceGuard],
      canActivate: [AgAuthGuard],
      loadComponent : () => import('./ag-mobile-layout/ag-mobile-layout.component').then(c => c.AgMobileLayoutComponent),
      loadChildren : () => import('./pages/mobile-routing').then(r => r.mobileRouting)
    },
    {
      path: '',
      canMatch : [systemDeviceGuardGuard],
      canActivate: [AgAuthGuard],
      loadComponent : () => import('./_metronic/layout/layout.component').then(c => c.LayoutComponent),
      loadChildren : () => import('./pages/routing').then(r => r.Routing)
    },
    {
      path: '',
      redirectTo: '/dashboard',
      pathMatch: 'full'
    },
    {
      path: 'error',
      canMatch : [systemDeviceGuardGuard],
      loadComponent : () => import('./modules/errors/errors.component').then(c => c.ErrorsComponent),
      loadChildren : () => import('./modules/errors/errors.route').then(r => r.errorRoutes)
    },
    { 
      path: '**',
      // redirectTo: 'error/404' 
      component : RedirectHandlerComponent
    }
];

export { appRoutes };