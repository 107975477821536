import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';


import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';

if (environment.production) {
  enableProdMode();

  if (window) {
    window.console.log = window.console.warn = window.console.info = function() {
      // Don't log anything.
    };
  }
}

// window.console.log = function () { }; 

// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .catch((err) => console.error(err));

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
