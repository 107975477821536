import { ViewportRuler } from '@angular/cdk/scrolling';
import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';

export const systemDeviceGuardGuard: CanMatchFn = (route, segments) => {

  const viewPortRuler = inject(ViewportRuler);
  // window.addEventListener('resize', () => window.location.reload());
  
  return viewPortRuler.getViewportSize().width >= 600;
};
