// Angular
import { Injectable, EventEmitter } from '@angular/core';
import moment from 'moment';

@Injectable()
export class SharedService {
    pageLoader = new EventEmitter<boolean>();

    constructor() {

    }

    startLoading() {
        this.pageLoader.emit(true);
    }

    sum(num1: number = 0, num2: number = 0) {
      return Number(num1 || 0) + Number(num2 || 0);
    } 

    flattenObject(ob) {
      // The object which contains the
      // final result
      let result = {};
      // loop through the object "ob"
      for (const i in ob) {
          // We check the type of the i using
          // typeof() function and recursively
          // call the function again
          if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
              const temp = this.flattenObject(ob[i]);
              for (const j in temp) {
   
                  // Store temp in result
                  result[i + '.' + j] = temp[j];
              }
          }
   
          // Else store ob[i] in result directly
          else {
              result[i] = ob[i];
          }
      }
      return result;
  };

    sumArrayOfObject(array: any[], key: string) {
      if (array && Array.isArray(array) && array.length > 0) {
        return array.reduce((acc, cur) => {
          return acc + Number(this.flattenObject(cur)[key] || 0);
        }, 0);
      }
      return 0;
    }

    stopLoading() {
        this.pageLoader.emit(false);
    }

    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    replaceAll(str, term, replacement) {
        return str.replace(new RegExp(this.escapeRegExp(term), 'g'), replacement);
    }

    getLabelByValue(array, val) {
        if(array && array.length > 0 && array.find(d => d.value === val)) {
           return array.find(d => d.value === val).label;
        } else {
          return '';
        } 
    }

    getMsgCount(isEnglish = false, isTamil = false, is_unicode = false, contentVal) {
        let remaining = '159 characters remaining in ';
        let msg_cnt = '1 message(s)';
        let sms_count = 1;
        var content = "";
        if (contentVal) {
          content = contentVal;
        }
        var charset7bit = { '@': 1, '£': 1, '$': 1, '¥': 1, 'è': 1, 'é': 1, 'ù': 1, 'ì': 1, 'ò': 1, 'Ç': 1, "\n": 1, 'Ø': 1, 'ø': 1, "\r": 1, 'Å': 1, 'å': 1, 'Δ': 1, '_': 1, 'Φ': 1, 'Γ': 1, 'Λ': 1, 'Ω': 1, 'Π': 1, 'Ψ': 1, 'Σ': 1, 'Θ': 1, 'Ξ': 1, 'Æ': 1, 'æ': 1, 'ß': 1, 'É': 1, ' ': 1, '!': 1, '"': 1, '#': 1, '¤': 1, '%': 1, '&': 1, "'": 1, '(': 1, ')': 1, '*': 1, '+': 1, ',': 1, '-': 1, '.': 1, '/': 1, '0': 1, '1': 1, '2': 1, '3': 1, '4': 1, '5': 1, '6': 1, '7': 1, '8': 1, '9': 1, ':': 1, ';': 1, '<': 1, '=': 1, '>': 1, '?': 1, '¡': 1, 'A': 1, 'B': 1, 'C': 1, 'D': 1, 'E': 1, 'F': 1, 'G': 1, 'H': 1, 'I': 1, 'J': 1, 'K': 1, 'L': 1, 'M': 1, 'N': 1, 'O': 1, 'P': 1, 'Q': 1, 'R': 1, 'S': 1, 'T': 1, 'U': 1, 'V': 1, 'W': 1, 'X': 1, 'Y': 1, 'Z': 1, 'Ä': 1, 'Ö': 1, 'Ñ': 1, 'Ü': 1, '§': 1, '¿': 1, 'a': 1, 'b': 1, 'c': 1, 'd': 1, 'e': 1, 'f': 1, 'g': 1, 'h': 1, 'i': 1, 'j': 1, 'k': 1, 'l': 1, 'm': 1, 'n': 1, 'o': 1, 'p': 1, 'q': 1, 'r': 1, 's': 1, 't': 1, 'u': 1, 'v': 1, 'w': 1, 'x': 1, 'y': 1, 'z': 1, 'ä': 1, 'ö': 1, 'ñ': 1, 'ü': 1, 'à': 1, "\f": 2, '^': 2, '{': 2, '}': 2, '\\': 2, '[': 2, '~': 2, ']': 2, '|': 2, '€': 2 };
        var matches = content.split("");
        var use_7bit = true;
        var length_7bit = 0;
        var length_16bit = 0;
        var parts = { 'sms_count': 1, 'chars_left': 159 };
        for (var i in matches) {
          var char = matches[i];
          if (use_7bit && charset7bit[char] === undefined) {
            use_7bit = false;
          }
          if (use_7bit) {
            length_7bit += charset7bit[char];
          }
          length_16bit++;
        }
    
        var sms_containers = {};
    
        if (use_7bit) {
          isEnglish = true;
          is_unicode = false;
          parts = this.smsCount(length_7bit, false);
        } else {
          isTamil = true;
          is_unicode = true;
          parts = this.smsCount(length_16bit, true);
        }
    
        remaining = parts.chars_left + ' characters remaining in';
        msg_cnt = parts.sms_count + ' message(s)';
        sms_count = parts.sms_count;
        return { remaining, msg_cnt, sms_count };
      };
    
      smsCount(chars, enc_16) {
        var characters_left = 0;
        var sms_count = 0;
        if (enc_16 === false && chars <= 159) {
          characters_left = 159 - chars;
          return {
            'sms_count': 1,
            'chars_left': characters_left
          };
        }
        if (enc_16 === true && chars <= 69) {
          characters_left = 69 - chars;
          return {
            'sms_count': 1,
            'chars_left': characters_left
          };
        }
        if (enc_16 === false) {
          sms_count = Math.ceil(chars / 153);
          characters_left = (sms_count * 153) - chars;
          return {
            'sms_count': sms_count,
            'chars_left': characters_left
          };
        } else {
          sms_count = Math.ceil(chars / 67);
          characters_left = (sms_count * 67) - chars;
          return {
            'sms_count': sms_count,
            'chars_left': characters_left
          };
        }
    };

    getThisMonth() {
      return { startDate:moment().startOf('month'), endDate:moment().endOf('month') };
    }

}