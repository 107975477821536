import { Injectable } from '@angular/core';

export interface history {
  title: string;
  path: string;
  time: Date;
};

@Injectable()
export class AppHistoryService {
  histories: history[] = [];

  constructor() {
    
  }

  addNew(title: string, path: string) {
    let index = this.existsIndex(title, path);
    if (index !== -1) {
      this.histories.splice(index, 1);
    }
    this.histories.unshift({
      title: title,
      path: path,
      time: new Date()
    });
  }

  existsIndex(title: string, path: string) {
    return this.histories.findIndex(data => {return data.title === title && data.path === path;});
  }
}