import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoggerService } from './logger.service';
import { RouteService } from './route.service';
import _ from 'lodash';
import { environment } from '../../../../../environments/environment';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsoleOverrideService {
  private isOverridden = false;
  private currentUrl: string = '';

  constructor(private logger: LoggerService, private router: Router, private routeService: RouteService) {
    this.initialize();
  }

  initialize() {
      // this.router.events.subscribe((event) => {
      //   if (event instanceof NavigationEnd && !this.isOverridden) {
      //     this.currentUrl = this.router.url;
      //       }
      // });

      // this.router.events.pipe(
      //   filter(event => event instanceof NavigationEnd)
      // ).subscribe(() => {
      //   this.currentUrl = this.router.url;
      //   console.log('Current URL:', this.router.url);
      // });

      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          const currentRoute = event.urlAfterRedirects;
          this.routeService.setCurrentRoute(currentRoute);
        }
      });
      this.routeService.currentRoute$.subscribe(route => {
        this.currentUrl = route;
      });
      
      console.log('Current URL in service:', this.currentUrl);
      //this.currentPage = this.formatUrl(currentUrl);// Capture the current URL
      this.overrideConsoleMethods();
      this.isOverridden = true;
      // Ensure methods are overridden only once
    
  }

  formatUrl(url: string): string {
    try {
      // Create a URL object directly from the provided URL
      const parsedUrl = new URL(url);
      return parsedUrl.pathname; 
    } catch (e) {
      console.error('Error parsing URL:', e);
      return url; 
    }
  }

  overrideConsoleMethods() {
    // Only override when the page is accessed
    //this.router.events.subscribe((event) => {
      //if (event instanceof NavigationEnd && !this.isOverridden) {

      const originalConsoleLog = console.log;
      const originalConsoleWarn = console.warn;
      const originalConsoleError = console.error;
      const originalConsoleInfo = console.info;
      // Page has been accessed, now override the console methods
      
      window.console.log = (message, ...args: any[]) => {
        this.logger.info(this.formatLogMessage(message, ...args));
        if(environment.allow_console_log) {
          originalConsoleLog.apply(console, [message, ...args]);  // Optional: retain original behavior
        }
      };

      window.console.warn = (message?: any, ...optionalParams: any[]) => {
        this.logger.warn(this.formatLogMessage(message, ...optionalParams));
        if(environment.allow_console_log) {
          originalConsoleWarn.apply(console, [message, ...optionalParams]); // Optional: retain original behavior
        }
        
      };

      window.console.error = (message?: any, ...optionalParams: any[]) => {
        this.logger.error(this.formatLogMessage(message, ...optionalParams));
        if(environment.allow_console_log) {
          originalConsoleError.apply(console, [message, ...optionalParams]);
        }
        
      };

      window.console.info = (message?: any, ...optionalParams: any[]) => {
        this.logger.info(this.formatLogMessage(message, ...optionalParams));
        if(environment.allow_console_log) {
          originalConsoleInfo.apply(console, [message, ...optionalParams]);
        }
        
      };
      
      // Log that the override has occurred for this page
      //this.logger.info(`Console methods overridden for page: ${event.urlAfterRedirects}`);
      //}
    //});
  }

  formatLogMessage(message: any, ...optionalParams: any[]): string {
    const formattedParams = optionalParams.map(param => this.formatLogContent(param)).join(' ');
    return `[Page: ${this.currentUrl}] ${this.formatLogContent(message)} ${formattedParams}`;
  }
  
  formatLogContent(content: any): string {
    if (_.isArray(content) || _.isObject(content)) {
      return JSON.stringify(content, null, 2); // Pretty print JSON for better readability
  } else {
      // Convert non-object types to string
      return String(content); // Convert to string if it's a number, boolean, etc.
  }
}

// const formatLogMessage = (msg) => {
//   // Check if msg is an array or object using lodash
//   if (_.isArray(msg) || _.isObject(msg)) {
//       return JSON.stringify(msg, null, 2); // Pretty print JSON for better readability
//   } else {
//       // Convert non-object types to string
//       return String(msg); // Convert to string if it's a number, boolean, etc.
//   }
// };


}
