import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipDefaultOptions, MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog'; 
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators'; 
import { AppDateAdapter, APP_DATE_FORMATS } from './ag-core/adapters/date.adapter';
import { A11yModule } from '@angular/cdk/a11y';
// import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatExpansionModule } from '@angular/material/expansion';
import {DragDropModule} from '@angular/cdk/drag-drop'; 
// import { NgxLoadingModule } from 'ngx-loading';
// import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
// import { CustomAdapter, CustomDateParserFormatter } from './ag-core/adapters/date-picker.utils';
import { NgxPrintModule } from 'ngx-print';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import {ClipboardModule} from '@angular/cdk/clipboard'; 
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { TextFieldModule } from '@angular/cdk/text-field'; 
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig, MatSnackBarModule } from '@angular/material/snack-bar';
export const agMatTooltipOptions: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchGestures: 'auto',
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
};
export const agMatSnackbarOptions : MatSnackBarConfig = {
  duration : 3000,
  verticalPosition : 'bottom',
  horizontalPosition : 'center',
  panelClass : ['custom-snackbar']
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatChipsModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatExpansionModule,
    MatBottomSheetModule,
    MatSnackBarModule,
    TextFieldModule,
    RxReactiveFormsModule,
    A11yModule,
    NgbPopoverModule,
    OverlayModule,
    // NgxSliderModule,
    DragDropModule,
    ClipboardModule,
    // NgxPrintModule,
    NzEmptyModule,
    NzStatisticModule,
    NzTypographyModule,
    NgxMatIntlTelInputComponent,
    // NgxLoadingModule.forRoot({})
  ],
  exports: [
    MatCheckboxModule,
    MatButtonModule,
    ClipboardModule,
    MatButtonToggleModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatBadgeModule,
    MatChipsModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatBottomSheetModule,
    MatSnackBarModule,
    RxReactiveFormsModule,
    A11yModule,
    // NgxSliderModule,
    OverlayModule,
    DragDropModule,
    NgxMatIntlTelInputComponent,
    NgbPopoverModule,
    NzStatisticModule,
    // NgxLoadingModule,
    NzEmptyModule,
    NzTypographyModule,
    NgxPrintModule
  ],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: agMatTooltipOptions},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: agMatSnackbarOptions}
  ]
})
export class MaterialModule { }
