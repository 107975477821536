import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.route';
import { AppProviders } from './app.providers';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(appRoutes),
        ...AppProviders
    ]
};
