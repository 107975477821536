import { CanDeactivate } from '@angular/router';

export interface FormComponent {
    isDirty : boolean;
}

export class PreventUnsavedChangesGuard implements CanDeactivate<FormComponent> {
    canDeactivate(
        component: FormComponent
    ) {
        if (component.isDirty) {
            return confirm('You have unsaved changes. Are you sure you want to leave this page?');
        }
        return true;
    } 
}